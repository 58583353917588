// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dmca-js": () => import("./../src/pages/dmca.js" /* webpackChunkName: "component---src-pages-dmca-js" */),
  "component---src-pages-earnings-js": () => import("./../src/pages/earnings.js" /* webpackChunkName: "component---src-pages-earnings-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-questions-1-js": () => import("./../src/pages/questions1.js" /* webpackChunkName: "component---src-pages-questions-1-js" */),
  "component---src-pages-questions-2-js": () => import("./../src/pages/questions2.js" /* webpackChunkName: "component---src-pages-questions-2-js" */),
  "component---src-pages-questions-3-js": () => import("./../src/pages/questions3.js" /* webpackChunkName: "component---src-pages-questions-3-js" */),
  "component---src-pages-questions-4-js": () => import("./../src/pages/questions4.js" /* webpackChunkName: "component---src-pages-questions-4-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

